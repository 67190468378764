/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable import/prefer-default-export */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable arrow-body-style */
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import {
  mdiTimerOutline,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import {
  getDashboardActives,
  getGroupsActive,
  getFacilitiesByGroupMin,
  getFieldsByFacility,
} from '@api'
import useCryptoJs from '@core/utils/useCryptoJs'
import useSelectOptions from '@core/utils/useSelectOptions'
import useFilters from '@core/utils/useFilters'

export default function useRecordingActivity() {
  // const vm = getCurrentInstance().proxy
  const { updateFilter, getFilterByModule } = useFilters()
  const { t } = useUtils()
  const { userData } = useCryptoJs()
  const {
    configOrganization,
    configFacility,
  } = useSelectOptions(true)

  const customOrganization = ref(0)
  const customFacility = ref(0)
  const customField = ref(0)
  const organizationsOptions = ref([])
  const facilitiesOptions = ref([])
  const fieldsOptions = ref([{
    id: 0,
    name: 'All Fields',
  }])

  const timerSearch = ref(null)

  const viewRangeOptions = computed(() => [
    { text: t('billing_cycle_types.daily'), value: 'D' },
    { text: t('billing_cycle_types.weekly'), value: 'W' },
    { text: t('billing_cycle_types.monthly'), value: 'M' },
    { text: t('billing_cycle_types.anually'), value: 'A' },
  ])

  const dateRangeOptions = computed(() => [
    { text: t('date_range.this_year'), value: 'TY' },
    { text: t('date_range.this_month'), value: 'TM' },
    { text: t('date_range.last_month'), value: 'LM' },
    { text: t('date_range.this_week'), value: 'TW' },
    { text: t('date_range.last_week'), value: 'LW' },
    { text: t('date_range.yesterday'), value: 'Y' },
    { text: t('date_range.today'), value: 'T' },
    { text: t('date_range.custom'), value: 'C' },
  ])

  const viewRangeFilter = ref('D')
  const dateRangeFilter = ref('TW')

  const modalFrom = ref(null)
  const modalTo = ref(null)
  const minDate = ref(
    new Date('2021-01-02').toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )
  const maxDate = ref(
    new Date(+new Date() + 12096e5).toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )
  const nowDate = ref(
    new Date().toLocaleString('sv-SE', {
      timeZone: 'America/New_York',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
  )
  const dateFrom = ref(nowDate.value)
  const dateTo = ref(nowDate.value)

  const activesSummary = ref(null)

  const recordingHoursOptions = ref({
    startTitle: t('statistics.actives_facility'),
    tooltip: 'tooltip',
    icon: mdiTimerOutline,
    color: '#16B1FF',
    subtitle: t('statistics.this_month'),
    statistics: '56',
    change: '+25%',
    showBtnDots: true,
    showChange: true,
  })
  const computedRecordingHoursOptions = computed(() => recordingHoursOptions.value)

  const fetchRecordingHours = async (group, facility) => {
    let response = null
    if (group) {
      response = await getDashboardActives(group, facility)
    } else if (userData.value && userData.value.role !== 'A') {
      response = await getDashboardActives(userData.value.group_id, facility)
    } else {
      response = await getDashboardActives(0, facility)
    }

    if (response.ok) {
      activesSummary.value = response.data

      recordingHoursOptions.value.statistics = activesSummary.value.cameras
      recordingHoursOptions.value.change = '+42%'
    }
  }

  const fetchSummary = async () => {
    let gruop = 0
    if (userData.value && userData.value.role !== 'A') gruop = userData.value.group_id
    else if (userData.value && userData.value.role === 'A' && customOrganization.value) gruop = customOrganization.value
    else gruop = 0

    await fetchRecordingHours(
      gruop,
      customFacility.value,
    )
  }

  const fetchFacilitiesByOrganization = async organizationId => {
    customFacility.value = 0
    let resp = null
    let facilities = []
    const all = [{
      id: 0,
      name: 'All Facilities',
    }]

    if (organizationId) {
      resp = await getFacilitiesByGroupMin(organizationId)
    } else if (customOrganization.value) {
      resp = await getFacilitiesByGroupMin(customOrganization.value)
    }

    if (resp && resp.ok) {
      facilities = resp.data
      if (facilities.length === 1) {
        facilitiesOptions.value = facilities
        customFacility.value = facilities.at(0).id
      } else if (facilities.length > 1) {
        facilitiesOptions.value = all.concat(facilities)
        customFacility.value = 0
      }
    } else {
      facilitiesOptions.value = all
    }
  }

  const fetchFieldsByFacilities = async facilityId => {
    customField.value = 0
    let resp = null
    let fields = []
    const all = [{
      id: 0,
      name: 'All Fields',
    }]

    if (facilityId) {
      resp = await getFieldsByFacility(facilityId)
    } else if (customOrganization.value) {
      resp = await getFieldsByFacility(customOrganization.value)
    }

    if (resp && resp.ok) {
      fields = resp.data
      if (fields.length === 1) {
        fieldsOptions.value = fields
        customFacility.value = fields.at(0).id
      } else if (fields.length > 1) {
        fieldsOptions.value = all.concat(fields)
        customField.value = 0
      }
    } else {
      fieldsOptions.value = all
    }
  }

  const timerFetch = ref(null)
  const debounceFetch = async (time = 1000) => {
    clearTimeout(timerFetch.value)
    timerFetch.value = setTimeout(async () => {
      await fetchFacilitiesByOrganization(customOrganization.value)
    }, time)
  }

  const timerFetchFields = ref(null)
  const debounceFetchFields = async (time = 1000) => {
    clearTimeout(timerFetchFields.value)
    timerFetchFields.value = setTimeout(async () => {
      await fetchFieldsByFacilities(customFacility.value)
    }, time)
  }

  const fetchOrganizations = async () => {
    const all = [{
      id: 0,
      name: 'All Organizations',
      logo: null,
      facilities: [],
    }]
    const resp = await getGroupsActive()
    if (resp.ok) {
      organizationsOptions.value = all.concat(resp.data)
      customOrganization.value = 0
    } else organizationsOptions.value = all
    customFacility.value = 0
  }

  watch([customFacility, dateRangeFilter, viewRangeFilter, dateFrom, dateTo], async () => {
    if (customFacility.value === null) customFacility.value = 0
    if (customField.value === null) customField.value = 0
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await debounceFetchFields(200)
      await fetchSummary()
    }, 500)
  })

  watch([customOrganization], async () => {
    if (customOrganization.value === null) customOrganization.value = 0
    clearTimeout(timerSearch.value)
    if (userData.value.role === 'A') await debounceFetch(200)
    timerSearch.value = setTimeout(async () => {
      await fetchSummary()
    }, 500)
  })

  // watch([customFacility], async () => {
  //   if (customFacility.value === null) customFacility.value = 0
  //   clearTimeout(timerSearch.value)
  //   if (userData.value.role === 'A') await debounceFetchFields(200)
  //   timerSearch.value = setTimeout(async () => {
  //     await fetchSummary()
  //   }, 500)
  // })

  watch([configOrganization], async () => {
    if (userData.value.role !== 'A') customOrganization.value = configOrganization.value
  })

  watch([configFacility], async () => {
    viewRangeFilter.value = 'D'
    dateRangeFilter.value = 'TW'
    dateFrom.value = null
    dateTo.value = null
    if (userData.value.role !== 'A') customFacility.value = configFacility.value
  })

  onMounted(async () => {
    if (userData.value.role !== 'A') {
      customOrganization.value = configOrganization.value
      customFacility.value = configFacility.value
      customField.value = 0
    } else {
      await fetchOrganizations()
      await debounceFetch(500)
    }

    await fetchSummary()
  })

  return {
    userData,

    viewRangeOptions,
    dateRangeOptions,
    viewRangeFilter,
    dateRangeFilter,
    modalFrom,
    modalTo,
    dateFrom,
    dateTo,
    minDate,
    maxDate,
    nowDate,

    computedRecordingHoursOptions,

    // fullCalendarOptions,

    organizationsOptions,
    facilitiesOptions,
    fieldsOptions,
    customOrganization,
    customFacility,
    customField,
    debounceFetch,
    fetchOrganizations,

    // i18n
    t,

    fetchSummary,
    fetchRecordingHours,
    updateFilter,
    getFilterByModule,
  }
}
