<template>
  <div id="dashboard">
    <v-card-title class="px-0 mb-5">
      {{ t('Recording Activity') }}

      <v-spacer></v-spacer>
      <v-btn
        small
        class="white--text"
        color="btnPrimary"
        @click="$router.replace({ name: 'views-reports-view'})"
      >
        {{ $t('tooltip.back') }}
      </v-btn>
    </v-card-title>

    <v-row class="px-2 ma-0 text-center">
      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="dateRangeFilter"
          class="bg--white"
          :items="dateRangeOptions"
          item-text="text"
          item-value="value"
          single-line
          outlined
          dense
          hide-details
          :placeholder="t('select.date_range')"
          @change="updateFilter('views-reports-recording-activity', 'custom', dateRangeFilter)"
        ></v-select>
      </v-col>

      <v-col
        cols="12"
        md="2"
      >
        <v-select
          v-model="viewRangeFilter"
          class="bg--white"
          :items="viewRangeOptions"
          item-text="text"
          item-value="value"
          single-line
          outlined
          dense
          hide-details
          :placeholder="t('select.view')"
          @change="updateFilter('views-reports-recording-activity', 'custom', viewRangeFilter)"
        ></v-select>
      </v-col>

      <v-col
        v-if="dateRangeFilter === 'C'"
        cols="12"
        md="2"
        class="pb-0"
      >
        <v-menu
          v-model="modalFrom"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateFrom"
              class="bg--white"
              :placeholder="t('transactions.from_date')"
              outlined
              readonly
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            color="secondary"
            :min="minDate"
            :max="nowDate"
            @input="modalFrom = false"
            @change="updateFilter('views-reports-recording-activity', 'dateFrom', dateFrom)"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        v-if="dateRangeFilter === 'C'"
        cols="12"
        md="2"
        class="pb-0"
      >
        <v-menu
          v-model="modalTo"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedDateTo"
              class="bg--white"
              :placeholder="t('transactions.to_date')"
              outlined
              readonly
              dense
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateTo"
            color="secondary"
            :min="dateFrom"
            :max="nowDate"
            @input="modalTo = false"
            @change="updateFilter('views-reports-recording-activity', 'dateTo', dateTo)"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        v-if="userData.role === 'A'"
        cols="12"
        md="3"
      >
        <v-select
          v-model="customOrganization"
          class="bg--white"
          :label="t('organizations.organization')"
          :placeholder="t('organizations.organization')"
          :items="organizationsOptions"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          @change="updateFilter('views-reports-recording-activity', 'organization', customOrganization)"
        ></v-select>
      </v-col>

      <v-col
        v-if="userData.role === 'A'"
        cols="12"
        md="3"
      >
        <v-select
          v-model="customFacility"
          class="bg--white"
          :label="t('facilities.facility')"
          :placeholder="t('facilities.facility')"
          :items="facilitiesOptions"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          :disabled="!customOrganization"
          :append-icon="facilitiesOptions.length === 1 || !customOrganization ? null : icons.mdiMenuDown"
          :readonly="facilitiesOptions.length === 1 || !customOrganization"
          @change="updateFilter('views-reports-recording-activity', 'facility', customFacility)"
        ></v-select>
      </v-col>

      <v-col
        v-if="userData.role === 'A'"
        cols="12"
        md="3"
      >
        <v-select
          v-model="customField"
          class="bg--white"
          :label="t('fields.field')"
          :placeholder="t('fields.field')"
          :items="fieldsOptions"
          item-text="name"
          item-value="id"
          outlined
          dense
          hide-details
          :disabled="!customFacility"
          :append-icon="fieldsOptions.length === 1 || !customFacility ? null : icons.mdiMenuDown"
          :readonly="fieldsOptions.length === 1 || !customFacility"
          @change="updateFilter('views-reports-recording-activity', 'field', customField)"
        ></v-select>
      </v-col>
    </v-row>

    <v-row class="mb-5 match-height">
      <v-col
        cols="12"
        sm="4"
      >
        <statistics-card-vertical-border
          :change="computedRecordingHoursOptions.change"
          :color="computedRecordingHoursOptions.color"
          :icon="computedRecordingHoursOptions.icon"
          :statistics="computedRecordingHoursOptions.statistics"
          :tooltip="computedRecordingHoursOptions.tooltip"
          :start-title="$t('dashboard.total_occupancy_hours')"
          :subtitle="'that last week'"
          :show-btn-dots="computedRecordingHoursOptions.showBtnDots"
          :show-change="computedRecordingHoursOptions.showChange"
        ></statistics-card-vertical-border>
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <statistics-card-vertical-border
          :change="computedRecordingHoursOptions.change"
          :color="computedRecordingHoursOptions.color"
          :icon="computedRecordingHoursOptions.icon"
          :statistics="computedRecordingHoursOptions.statistics"
          :tooltip="computedRecordingHoursOptions.tooltip"
          :start-title="$t('dashboard.total_occupancy_hours')"
          :subtitle="'that last week'"
          :show-btn-dots="computedRecordingHoursOptions.showBtnDots"
          :show-change="computedRecordingHoursOptions.showChange"
        ></statistics-card-vertical-border>
      </v-col>

      <v-col
        cols="12"
        sm="4"
      >
        <statistics-card-vertical-border
          :change="computedRecordingHoursOptions.change"
          :color="computedRecordingHoursOptions.color"
          :icon="computedRecordingHoursOptions.icon"
          :statistics="computedRecordingHoursOptions.statistics"
          :tooltip="computedRecordingHoursOptions.tooltip"
          :start-title="$t('dashboard.total_occupancy_hours')"
          :subtitle="'that last week'"
          :show-btn-dots="computedRecordingHoursOptions.showBtnDots"
          :show-change="computedRecordingHoursOptions.showChange"
        ></statistics-card-vertical-border>
      </v-col>

      <v-col
        cols="12"
      >
        <!-- v-if="!isInsightOpen && facilityOpen" -->
        <full-calendar
          ref="refFullCalendar"
          :options="fullCalendarOptions"
        ></full-calendar>
      </v-col>

      <!-- <v-col
        cols="12"
        sm="4"
      >
        <full-calendar
          v-if="!isInsightOpen && facilityOpen"
          ref="refFullCalendar"
          :options="fullCalendarOptions"
        >
          <template v-slot:resourceLabelContent="arg">
            <div
              class="d-flex flex-column align-center justify-center  black--text"
              :style="`
                min-height: ${$vuetify.breakpoint.smAndDown ? '104px' : '96px'};
                height: ${$vuetify.breakpoint.smAndDown ? '104px' : '96px'};
              `"
            >
              <p class="text-body-1 font-weight-bold ma-0">
                {{ arg.resource.title }}
              </p>
              <p
                v-if="arg.resource.extendedProps.sport_name"
                class="ma-0"
              >
                {{ arg.resource.extendedProps.sport_name }}
              </p>
            </div>
          </template>

          <template v-slot:eventContent="arg">
            <event-timeline
              :event="arg"
            ></event-timeline>
          </template>
        </full-calendar>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-unresolved */
import { mdiCalendarArrowLeft, mdiCalendarArrowRight, mdiHelp } from '@mdi/js'
import {
  ref, computed, onMounted, getCurrentInstance,
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { formatCurrency, formatStdDate } from '@core/utils'
import useSelectOptions from '@core/utils/useSelectOptions'

import FullCalendar from '@fullcalendar/vue'
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import interactionPlugin from '@fullcalendar/interaction'

import StatisticsCardVerticalBorder from '@/@core/components/statistics-card/StatisticsCardVerticalBorder.vue'

// composition function
import useRecordingActivity from './useRecordingActivity'

export default {
  components: {
    StatisticsCardVerticalBorder,
    FullCalendar,
  },
  setup() {
    const {
      userData,
      viewRangeOptions,
      dateRangeOptions,
      viewRangeFilter,
      dateRangeFilter,
      modalFrom,
      modalTo,
      dateFrom,
      dateTo,
      minDate,
      maxDate,
      nowDate,

      computedRecordingHoursOptions,

      // fullCalendarOptions,

      organizationsOptions,
      facilitiesOptions,
      fieldsOptions,
      customOrganization,
      customFacility,
      customField,

      t,
      fetchSummary,
      updateFilter,
      getFilterByModule,
    } = useRecordingActivity()
    const { configObjFacility } = useSelectOptions()
    const vm = getCurrentInstance().proxy

    const computedDateFrom = computed(() => formatStdDate(dateFrom.value, 'date', configObjFacility.value.is12Hour, vm.$i18n.locale))
    const computedDateTo = computed(() => formatStdDate(dateTo.value, 'date', configObjFacility.value.is12Hour, vm.$i18n.locale))

    const refFullCalendar = ref(null)
    const fullCalendarOptions = computed(() => {
      const options = {
        // height: calHeight.value,
        width: '100%',
        editable: true,
        plugins: [
          resourceTimeGridPlugin,
          interactionPlugin,
        ],

        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        initialView: 'resourceTimeGrid',

        // headerToolbar: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridMonth, timeGridWeek, timeGridDay',
        },

        // headerToolbar: {
        //   center: 'dayGridMonth,timeGridFourDay', // buttons for switching between views
        // },

        // nowIndicator: true,
        views: {
          resourceTimelineDay: {
            slotDuration: '00:15',
            slotLabelInterval: '00:15',
            slotLabelFormat: {
              hour: 'numeric',
              minute: '2-digit',
              meridiem: 'short',
              omitZeroMinute: false,
              hour12: configObjFacility.value.is12Hour,
            },
            slotMinWidth: '100px',
            eventMinHeight: '75px',
            eventMinWidth: '100px',
            eventMaxStack: 1,

            // slotMinTime: '09:00:00',
            // slotMaxTime: '18:00:00',
          },
          resourceTimelineWeek: {
            slotDuration: '00:15',
            slotLabelInterval: '00:15',
            slotMinWidth: '100px',
            eventMinHeight: '75px',
            eventMinWidth: '100px',
            eventMaxStack: 1,
          },
        },
        resourceAreaColumns: [
          {
            field: 'title',
            headerContent: '',
          },
        ],

        resourceAreaWidth: vm.$vuetify.breakpoint.smAndDown ? '30%' : '250px',
        eventMinHeight: '75px',
        eventMinWidth: '100px',
        eventMaxStack: 1,
        initialEvents: [],
        events: [
          {
            resourceId: 'a',
            title: 'event 1',
            start: '2025-03-10',
            end: '2025-03-12',
          },
          {
            resourceId: 'b',
            title: 'event 3',
            start: '2025-03-11T12:00:00+00:00',
            end: '2025-03-12T06:00:00+00:00',
          },
          {
            resourceId: 'c',
            title: 'event 4',
            start: '2025-03-11T07:30:00+00:00',
            end: '2025-03-11T09:30:00+00:00',
          },
          {
            resourceId: 'd',
            title: 'event 5',
            start: '2025-03-11T10:00:00+00:00',
            end: '2025-03-11T15:00:00+00:00',
          },
          {
            resourceId: 'a',
            title: 'event 2',
            start: '2025-03-11T09:00:00+00:00',
            end: '2025-03-11T14:00:00+00:00',
          },
          {
            resourceId: 'e',
            title: 'event e',
            start: '2025-03-11T09:00:00+00:00',
            end: '2025-03-11T14:00:00+00:00',
          },
          {
            resourceId: 'f',
            title: 'event f',
            start: '2025-03-11T09:00:00+00:00',
            end: '2025-03-11T10:00:00+00:00',
            color: '#ff9f89',
          },
          {
            resourceId: 'g',
            title: 'event g',
            start: '2025-03-11T09:00:00+00:00',
            end: '2025-03-11T11:00:00+00:00',
            display: 'background',
            color: '#ff9f89',
          },
        ],
        resources: [
          { id: 'a', title: 'Room A' },
          { id: 'b', title: 'Room B' },
          { id: 'c', title: 'Room C' },
          { id: 'd', title: 'Room D' },
          { id: 'e', title: 'Room E' },
          { id: 'f', title: 'Room F' },
          { id: 'g', title: 'Room G' },
          { id: 'h', title: 'Room H' },
          { id: 'i', title: 'Room I' },
          { id: 'j', title: 'Room J' },
          { id: 'k', title: 'Room K' },
        ],

        // scrollTime: '14:00:00',
        scrollTimeReset: false,

        // dateClick: onDateClick,
        // eventClick: onEventClick,
        // viewDidMount: handleViewDidMount,
      }

      return options
    })

    onMounted(async () => {
      const filtersStored = getFilterByModule('views-reports-recording-activity')
      if (filtersStored.organization) customOrganization.value = filtersStored.organization
      if (filtersStored.facility) customFacility.value = filtersStored.facility
      if (filtersStored.custom) dateRangeFilter.value = filtersStored.custom
      if (filtersStored.view) viewRangeFilter.value = filtersStored.view
      if (filtersStored.dateFrom) dateFrom.value = filtersStored.dateFrom
      if (filtersStored.dateTo) dateTo.value = filtersStored.dateTo

      await fetchSummary()
    })

    return {
      userData,

      viewRangeOptions,
      dateRangeOptions,
      viewRangeFilter,
      dateRangeFilter,
      modalFrom,
      modalTo,
      dateFrom,
      dateTo,
      minDate,
      maxDate,
      nowDate,

      computedDateFrom,
      computedDateTo,
      configObjFacility,

      computedRecordingHoursOptions,

      refFullCalendar,
      fullCalendarOptions,

      organizationsOptions,
      facilitiesOptions,
      fieldsOptions,
      customOrganization,
      customFacility,
      customField,

      icons: {
        mdiCalendarArrowLeft,
        mdiCalendarArrowRight,
        mdiHelp,
      },

      // i18n
      t,

      avatarText,
      formatCurrency,
      formatStdDate,

      fetchSummary,
      updateFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
#payment-list {
  .payment-list-actions {
    max-width: 7.81rem;
  }
  .payment-list-search {
    max-width: 10.625rem;
  }
  .payment-list-status {
    max-width: 11.3rem;
  }
}
</style>

<style lang="scss">
.fc-datagrid-header .fc-datagrid-cell-frame {
  display: flex !important;
}

// FullCalendar
.fc-scroller {
  margin-right: -7px;
}

.fc-datagrid-cell > .fc-datagrid-cell-frame {
  justify-content: center !important;
}

.fc-timeline-slot-cushion .fc-scrollgrid-sync-inner {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #000000 !important;
}

.fc .fc-timeline-slot-cushion {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #000000 !important;
}

// .fc-theme-standard td, .fc-theme-standard th {
//     border: 1px solid #dddddd !important;
// }

table.fc-scrollgrid-sync-table > tbody > tr > td.fc-timeline-lane.fc-resource {
  background-color: #f5f5f5 !important;
}

.fc {
  background-color: #ffffff !important;
  border-radius: 10px !important;

  table {
    border-radius: 10px !important;

    thead > tr > th:last-child {
      border-top-right-radius: 10px !important;
    }
  }
}
</style>
